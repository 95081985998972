@import '../../const/settings.scss';
.wpSkeletonItem{
    // width: 100%;
    .skeletonImage{
        width: 100%;
        height: 320px;
        border-radius: 16px 16px 0 0;
    }
    .wpSkeletonInfo{
        padding: 24px;
        display: flex;
        .skeletonLogo{
            margin-right: 16px;
            .ant-skeleton-avatar-circle{
                height: 80px;
                width: 80px;
            }
        }
        .wpSkeletonDetails{
            display: flex;
            flex-direction: column;
            width: 100%;
            .skeletonInput1{
                margin-bottom: 4px;
                width: 100%;
            }
            .skeletonInput2{
                margin-bottom: 4px;
                width: 100%;
            }
            .wpSkeletonButton{
                .skeletonButton1{
                    margin-right: 8px;
                    width: 140px;
                }
                .skeletonButton2{
                    width: 140px;
                }
            }
        }
    }
}
.see-detail {
    height: 35px !important;
    width: 115px !important;
    border-radius: 5px !important;
    border: 1px solid var(--Color-2, #00c5d7) !important;
    background: var(--Color-3, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0;
    span {
      color: var(--Color-4, #363636);
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 150%; /* 21px */
      text-transform: capitalize;
    }
    @include media(md) {
      span {
        font-size: 14px !important;
      }
      height: 30px !important;
    }
  }
